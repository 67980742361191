import { useQuery } from "react-query";
import {
  BANK_URL,
  PRODUCT_URL,
  PRODUCT_URL_TYPE,
  SOURCING_PRODUCTS
} from "../api/urls";
import { useAxios } from "./useAxios";

export const useProduce = () => {
  const axios = useAxios();
  return useQuery(
    "products",
    async () => {
      const { data } = await axios.get(PRODUCT_URL);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};

export const useProduceTypes = () => {
  const axios = useAxios();
  return useQuery(
    "product-types",
    async () => {
      const { data } = await axios.get(PRODUCT_URL_TYPE);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};

export const useBanks = () => {
  const axios = useAxios();
  return useQuery(
    "banks",
    async () => {
      const { data } = await axios.get(BANK_URL);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};

export const useSourcedProducts = (filters, offset = "") => {
  const axios = useAxios();
  return useQuery(["sourced", filters, offset], async () => {
    const {
      data: { returned_resultset, available_resultset_size }
    } = filters
      ? await axios.get(`${SOURCING_PRODUCTS}?${filters}${offset}&limit=10`)
      : [];
    return { returned_resultset, available_resultset_size };
  });
};
