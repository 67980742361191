import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,
  FormControl,
  FormLabel,
  Input,
  Select
} from "@chakra-ui/react";
import { useHandlers, useSuppliers } from "../hooks/useSuppliers";
import { useForm } from "react-hook-form";
import { useSourcedProducts } from "../hooks/useProduceType";
import { format } from "date-fns";

const History = () => {
  const [offset, setOffset] = React.useState("&offset=0");
  const [activePage, setActivePage] = React.useState(1);
  const { data: supplier } = useSuppliers();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    getValues,
    setValue
  } = useForm();
  const formFilters = watch("supplier_id");
  const { data: products, isLoading } = useSourcedProducts(
    formFilters ? `&supplier_id=${formFilters}` : ``,
    offset
  );

  console.log("products", products, formFilters);

  return (
    <Box>
      <FormControl isInvalid={errors?.name?.message}>
        <FormLabel>Supplier Name</FormLabel>
        <Select
          placeholder="Select agent..."
          name="supplier_id"
          focusBorderColor="brand.800"
          {...register("supplier_id", { required: "Supplier is required" })}
        >
          {supplier
            ?.sort((a, b) => a.display_name.localeCompare(b.display_name))
            .map(supplier => (
              <option key={supplier.id} value={supplier?.id}>
                {supplier?.display_name}
              </option>
            ))}
        </Select>
      </FormControl>
      <TableContainer mt="2">
        <Table size="md" variant="striped">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>LPO NUmber</Th>
              <Th>Produce Type</Th>
              <Th>Quality Score</Th>
              <Th>Net Weight</Th>
              <Th>Cost/Kg</Th>
              <Th>Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {products?.returned_resultset?.map(product => (
              <Tr key={product.id}>
                <Td>
                  {format(new Date(product.created_at), "dd MMM yyyy HH:mm a")}
                </Td>
                <Td>{product.lpo_number}</Td>
                <Td>{product.product_type?.name}</Td>
                <Td>{product.quality_score}</Td>
                <Td>{product.total_net_weight}</Td>
                <Td>{product.cost_per_kg}</Td>
                <Td>{product.total_cost}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default History;
