import { useQuery } from "react-query";
import { HANDLERS_URL, ORG_BANK_URL, SUPPLIER_URL } from "../api/urls";
import { useAxios } from "./useAxios";

export const useSuppliers = () => {
  const axios = useAxios();
  return useQuery(
    "suppliers",
    async () => {
      const { data } = await axios.get(`${SUPPLIER_URL}&limit=10000`);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};

export const useHandlers = () => {
  const axios = useAxios();
  return useQuery(
    "handlers",
    async () => {
      const { data } = await axios.get(HANDLERS_URL);
      const { returned_resultset } = data;
      return returned_resultset;
    },
    {}
  );
};
export const useOrganisationBanks = id => {
  const axios = useAxios();
  return useQuery(["org_banks", id], async () => {
    if (!id) return [];
    const {
      data: { returned_resultset }
    } = await axios.get(`${ORG_BANK_URL}?organisation_id=${id}`);
    return returned_resultset;
  });
};
