import React from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Spacer,
  Text,
  Container
} from "@chakra-ui/react";
import { FaHistory, FaShoppingBasket } from "react-icons/fa";
import Form from "../components/Form";
import History from "../components/History";
const Home = () => {
  return (
    <Container maxW="container.lg">
      <Tabs
        isFitted
        defaultValue="sourcing"
        colorScheme="teal"
        variant="enclosed-colored"
        mt="2"
      >
        <TabList bg="bg.muted" rounded="l3" w="100%">
          <Tab outline="none" value="sourcing">
            <Flex alignItems="center" gap="2" px="2">
              <FaShoppingBasket />
              <Text px="2">
                <b>Sourcing</b>
              </Text>
            </Flex>
          </Tab>
          <Tab value="history">
            <Flex alignItems="center" gap="2" px="2">
              <FaHistory />
              <Spacer />
              <Text px="2">
                <b>History</b>
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel value="sourcing">
            <Form />
          </TabPanel>
          <TabPanel value="history">
            <History />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default Home;
